var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TTableAdvance',{attrs:{"items":_vm.users,"fields":_vm.fields,"store":"auth.users","resource":"/admin/users","enterable":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"role_id",fn:function({ item }){return [_c('td',[(item.role)?_c('SMessageRole',{attrs:{"editable":"","id":item.role_id,"content":item.role.name},on:{"change":function($event){_vm.$store
            .dispatch('auth.users.detail.update', {
              role_id: $event,
            })
            .then(() => {
              _vm.$store.dispatch('auth.users.fetch');
            })}}}):_vm._e()],1)]}},{key:"name",fn:function({ item }){return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"email",fn:function({ item }){return [_c('td',[_c('TMessage',{attrs:{"content":item.email,"noTranslate":""}})],1)]}},{key:"tel",fn:function({ item }){return [_c('td',[_c('TMessage',{attrs:{"content":item.tel,"noTranslate":""}})],1)]}},{key:"created_at",fn:function({ item }){return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"email-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.email},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "email", $event)},_vm.filterChange]}})]},proxy:true},{key:"tel-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.tel},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "tel", $event)},_vm.filterChange]}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setCreatedAtFilter]}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }