<template>
  <TTableAdvance
    :items="users"
    :fields="fields"
    store="auth.users"
    @click-clear-filter="clearFilter"
    resource="/admin/users"
    enterable
  >
    <template #role_id="{ item }">
      <td>
        <SMessageRole
          v-if="item.role"
          editable
          :id="item.role_id"
          :content="item.role.name"
          @change="
            $store
              .dispatch('auth.users.detail.update', {
                role_id: $event,
              })
              .then(() => {
                $store.dispatch('auth.users.fetch');
              })
          "
        />
      </td>
    </template>
    <template #name="{ item }">
      <td>
        <TMessage :content="item.name" noTranslate />
      </td>
    </template>
    <template #email="{ item }">
      <td>
        <TMessage :content="item.email" noTranslate />
      </td>
    </template>
    <template #tel="{ item }">
      <td>
        <TMessage :content="item.tel" noTranslate />
      </td>
    </template>
    <template #created_at="{ item }">
      <td>
        <TMessageDateTime :content="item.created_at" small />
      </td>
    </template>
    <template #id-filter>
      <TInputText :value.sync="filter.id" @update:value="filterChange" />
    </template>
    <template #name-filter>
      <TInputText :value.sync="filter.name" @update:value="filterChange" />
    </template>
    <template #email-filter>
      <TInputText :value.sync="filter.email" @update:value="filterChange" />
    </template>
    <template #tel-filter>
      <TInputText :value.sync="filter.tel" @update:value="filterChange" />
    </template>
    <template #created_at-filter>
      <TInputDateTimeRange
        :value.sync="dateRange"
        @update:value="setCreatedAtFilter"
      />
    </template>
  </TTableAdvance>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "User id" },
        {
          key: "name",
          label: "Name",
          _style: "min-width: 200px; width: 200px",
        },
        { key: "email", label: "Email" },
        { key: "tel", label: "Tel" },
        {
          key: "role_id",
          label: "Role",
          _classes: "text-wrap",
          _style: "min-width: 250px; width: 250px",
        },
        {
          key: "created_at",
          sorter: true,
          label: "Created at",
          _style: "width: 250px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("auth.users.push-query", {
        "filter[role_id]": this.id,
      });
    }
  },
  computed: {
    ...mapGetters({
      users: "auth.users.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    filterChange() {
      let defaultFilter = {
        "filter[role_id]": this.id,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
        ...defaultFilter,
      });
      this.$store.dispatch("auth.users.apply-query", filterFields);
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
