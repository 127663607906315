<template>
  <TTableAdvance
    :items="resourceList"
    :fields="fields"
    noFilter
    store="auth.resources"
    resource=""
  >
    <template #id="{ item }">
      <td>
        <TMessage :content="item.name" noTranslate />
      </td>
    </template>
    <template #create="{ item }">
      <td class="text-center">
        <div v-for="scope in item.scope" :key="scope.id">
          <TInputCheckbox
            :disabled="readonly || updating"
            :checked="scope.permission.create"
            @change="change(item.id, 'create', scope.id)"
          />
        </div>
      </td>
    </template>
    <template #read="{ item }">
      <td class="text-center">
        <div v-for="scope in item.scope" :key="scope.id">
          <TInputCheckbox
            :disabled="readonly || updating"
            :checked="scope.permission.read"
            @change="change(item.id, 'read', scope.id)"
          />
        </div>
      </td>
    </template>
    <template #update="{ item }">
      <td class="text-center">
        <div v-for="scope in item.scope" :key="scope.id">
          <TInputCheckbox
            :disabled="readonly || updating"
            :checked="scope.permission.update"
            @change="change(item.id, 'update', scope.id)"
          />
        </div>
      </td>
    </template>
    <template #delete="{ item }">
      <td class="text-center">
        <div v-for="scope in item.scope" :key="scope.id">
          <TInputCheckbox
            :disabled="readonly || updating"
            :checked="scope.permission.delete"
            @change="change(item.id, 'delete', scope.id)"
          />
        </div>
      </td>
    </template>
    <template #scope="{ item }">
      <td>
        <div v-for="scope in item.scope" :key="scope.id">
          <TMessage
            :content="scope.name"
            bold
            :noTranslate="scope.noTranslate || false"
          />
        </div>
      </td>
    </template>
  </TTableAdvance>
</template>

<script>
export default {
  props: {
    objectId: {
      type: String,
      required: true,
    },
    store: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "id", label: "Permission" },
        { key: "scope", label: "Scope", _style: "width: 200px" },
        { key: "read", label: "Read", _classes: "text-center" },
        { key: "create", label: "Create", _classes: "text-center" },
        { key: "update", label: "Update", _classes: "text-center" },
        { key: "delete", label: "Delete", _classes: "text-center" },
      ],
    };
  },
  created() {
    this.$store.dispatch("auth.resources.fetch.if-first-time");
  },
  watch: {
    objectId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch(`${this.store}.apply-query`, {
            "filter[object_id]": id,
          });
        }
      },
    },
  },
  computed: {
    updating() {
      return this.$store.getters[`${this.store}.detail.updating`];
    },
    permissions() {
      return this.$store.getters[`${this.store}.list`];
    },
    resources() {
      return this.$store.getters["auth.resources.list"];
    },
    resourceList() {
      let rs = this.resources.map((resource) => {
        let item = {
          id: resource.id,
          name: resource.name,
          scope: [{ id: "*", name: "All", permission: {} }],
        };
        if (resource.id === "orders") {
          item.scope = [
            ...item.scope,
            { id: "retail", name: "Retail" },
            { id: "wholesale", name: "Wholesale" },
            { id: "shipment", name: "Shipment" },
            { id: "payment", name: "Payment Order" },
            { id: "auction", name: "Auction Order" },
          ];
        }
        if (resource.id === "imports") {
          item.scope = [
            ...item.scope,
            {
              id: "goods-delivery-boxs",
              name: "goods-delivery-boxs",
              noTranslate: true,
            },
          ];
        }
        const permissions = this.permissions.filter(
          (x) => x.resource_id === item.id
        );
        item.scope.forEach((scope) => {
          let permission = permissions.find(
            (permission) => permission.scope === scope.id
          );
          scope.permission = permission
            ? permission
            : { create: false, read: false, update: false, delete: false };
        });
        return item;
      });
      return rs;
    },
    type() {
      return this.store === "auth.permissions" ? "user" : "role";
    },
  },
  methods: {
    change(id, type, scope) {
      const permission = this.permissions.find(
        (x) => x.resource_id === id && x.scope === scope
      );
      const typeList = ["read", "create", "update", "delete"];
      let code = "";
      if (type === "all") {
        if (permission && permission.code === "1111") code = "0000";
        else code = "1111";
      }
      if (!permission) {
        // Code
        if (type !== "all") {
          typeList.forEach((x) => {
            const result = type === x ? "1" : "0";
            code = code.concat(result);
          });
        }
        // Create permission
        this.$store.dispatch(`${this.store}.create`, {
          object_id: this.objectId,
          object_type: this.type,
          resource_id: id,
          code: code,
          scope: scope,
        });
      }

      if (permission) {
        //CodeUpdate
        if (type !== "all") {
          typeList.forEach((x) => {
            const result = type === x ? +!permission?.[x] : +permission?.[x];
            code = code.concat(result);
          });
        }
        //Update permission
        this.$store.commit(`${this.store}.select`, permission.id);
        this.$store.dispatch(`${this.store}.detail.update`, {
          resource_id: id,
          object_id: this.id,
          code: code,
        });
      }
    },
  },
};
</script>
