<template>
  <TTabs
    :tabs="tabs"
    @change-tab="$router.push({ query: { tab: $event } })"
    :activeTab="$route.query.tab"
  >
    <template #users>
      <Users />
    </template>
    <template #role> </template>
    <template #authorization>
      <Permissions />
    </template>
  </TTabs>
</template>
<script>
import Permissions from "./components/Permissions.vue";
import Users from "./components/Users.vue";
export default {
  components: { Users, Permissions },
  data() {
    return {
      tabs: [
        { key: "users", name: "User management", icon: "cisUser" },
        // { key: "role", name: "Role management", icon: "cisMap" },
        {
          key: "authorization",
          name: "Authorization",
          icon: "cisPeople",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
