<template>
  <CCard>
    <CCardHeader class="d-flex">
      <TMessage class="my-auto" content="Authorization" bold />
      <TSpinner :loading="fetching"/>
    </CCardHeader>
    <CCardBody class="mb-2 w-100 mx-auto" style="max-width: 800px">
      <Permissions :objectId="id" store="auth.permissions_role" />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex';
import Permissions from "../../components/Permissions.vue";
export default {
  components: { Permissions },
  computed: {
    ...mapGetters({
      fetching: "auth.permissions_role.fetching"
    }),
    id() {
      return this.$route.params.id;
    }
  },
};
</script>
